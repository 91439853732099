h2 {
  color: var(--color-white);
  font-family: var(--font-family-primary);
  font-size: 6rem;
  position: relative;
  &:after {
    @extend %line-style;
    bottom: 0;

  }
}
