.c-card{
  width: 100%;
  border: 6px solid var(--color-primary);
  border-radius: 1rem;
  position: relative;
  padding: 8%;
  background-color: var(--color-primary);
  &:before{
    content: "";
    display: block;
    padding-bottom: 150%;
  }
  &__description{
    margin: 0;
    position: absolute;
    font-size: 3.5rem;
    color: var(--color-text-primary);
    width: calc(100% - 8%);
    left: 4%;
    top: 4%;
    white-space: pre-line;
  }
  &__num{
    position: absolute;
    top: -36px;
    right: 5px;
    color: var(--color-white);
    font-size: 2.5rem;
    font-weight: 600;
  }
}
