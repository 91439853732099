@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?omefor');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?omefor#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?omefor') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?omefor') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?omefor##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mime {
  &:before {
    content: $icon-mime; 
  }
}
.icon-draw {
  &:before {
    content: $icon-draw; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-next {
  &:before {
    content: $icon-next; 
  }
}
.icon-left-arrow {
  &:before {
    content: $icon-left-arrow; 
  }
}
.icon-delete {
  &:before {
    content: $icon-delete; 
  }
}
.icon-add {
  &:before {
    content: $icon-add; 
  }
}
.icon-cancel {
  &:before {
    content: $icon-cancel; 
  }
}
.icon-hot {
  &:before {
    content: $icon-hot; 
  }
}
.icon-funny {
  &:before {
    content: $icon-funny; 
  }
}
.icon-sexual {
  &:before {
    content: $icon-sexual; 
  }
}
.icon-spicy {
  &:before {
    content: $icon-spicy; 
  }
}
.icon-extreme {
  &:before {
    content: $icon-extreme; 
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil; 
  }
}
.icon-profile-user {
  &:before {
    content: $icon-profile-user; 
  }
}
.icon-exclamation {
  &:before {
    content: $icon-exclamation; 
  }
}
.icon-question {
  &:before {
    content: $icon-question; 
  }
}

