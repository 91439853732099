.o-absolute-center {
  height: 100vh;
  max-height: 70vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
