@import "src/styles/imports/bootstrap/breakpoints.imports";

.c-deck {
  width: 100%;
  padding: 10% 20%;
  display: inline-flex;
  align-items: center;

  &:hover {
    .c-deck__card--left {
      transform: rotate(0) translate(0, -50%);

    }

    .c-deck__card--right {
      transform: rotate(0) translate(0, -50%);
    }
  }

  &__container {
    position: relative;
    width: 100%;
    padding-top: 150%;
    min-width: 3.5rem;
    padding-top: 150%;
    display: inline-block;
    cursor: pointer;
  }

  &__card {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 100%;
    border-radius: .5rem;
    border: 2px solid;
    transform: translateY(-50%);
    background-image: url("../../../../assets/images/pattern.webp");
    background-repeat: repeat;
    z-index: 2;
    transition: transform 0.25s ease-in-out;


    &:before {
      font-family: "icomoon";
      font-size: 12vw;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include media-breakpoint-up(lg) {
        font-size: 7vw;
      }
    }

    &--left {
      z-index: 2;
      transform: rotate(-20deg) translate(25%, -50%);
    }

    &--right {
      z-index: 1;
      transform: rotate(20deg) translate(-25%, -50%);
    }
  }

  &--selected {
    .c-deck__card {
      border-color: var(--color-primary);
    }
  }

  &--mini {
    padding: 0 2rem;

    .c-deck__container {
      height: 5rem;
      width: 3.5rem;
      padding-top: 100%;
    }

    .c-deck__card {
      border-width: 2px;

      &:before {
        font-size: 2rem;
      }

      //&--left {
      //  transform: rotate(-20deg) translate(-4px, -1px)
      //}
      //&--right {
      //  transform: rotate(20deg) translate(4px, -1px)
      //}

    }
  }

  &--shuffled {
    .c-deck__card, .c-deck__card--left, .c-deck__card--right {
      z-index: 0;
    }

  }
}
