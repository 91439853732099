:root{
  --color-primary: #ccff00;
  --color-primary-30: rgb(106 213 235 / 30%);
  --color-actual-user: #FFFFFF;
  --color-text-primary: #333333;
  --color-text-secondary: #FFFFFF;

  --color-facebook: #1778F2;

  --color-background: var(--color-primary);
  --color-background-secondary: #2d0101;
  //
  //--color-warning: #FBD87F;
  --color-error: #e21313;
  --color-success: #13f129;
  //--color-info: #2D7DD2;

  --color-white: #FFFFFF;
  --color-white-40: rgb(255 255 255 / 40%);

  --color-black: #000000;
  --color-black--50: rgba(0, 0, 0, 0.5);

  --color-funny: #ffe600;
  --color-spicy: #EA9010;
  --color-hot: #F8333C;
  --color-extreme: #A50104;
  --color-sexual: #60005e;

  --color-grey-20: #222;
  --color-grey-30: #333;
  --color-grey-40: #c1c1c1;
  --color-grey-700: #DBDBDB;

  --color-backrgound-global: #151515;
}
