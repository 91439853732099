$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-mime: "\e90e";
$icon-draw: "\e910";
$icon-user: "\e90f";
$icon-next: "\e90d";
$icon-left-arrow: "\e90c";
$icon-delete: "\e90b";
$icon-add: "\e90a";
$icon-cancel: "\e909";
$icon-hot: "\e904";
$icon-funny: "\e903";
$icon-sexual: "\e905";
$icon-spicy: "\e906";
$icon-extreme: "\e907";
$icon-pencil: "\e908";
$icon-profile-user: "\e902";
$icon-exclamation: "\e900";
$icon-question: "\e901";

