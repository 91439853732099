.p-dialog{
  background-color: var(--color-white);
  max-height: 90vh;
  padding: 16px;
  width: 90vw;
  max-width: 480px;
  border-radius: 4px;
  min-height: 280px;
  color: var(--color-text-primary);
  //background-image: url(/images/background-lines-alt.svg);
  //background-size: 350%;
  //background-position: 62% 162%;

  .p-dialog-header {
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 34px;
    margin-bottom: 16px;
  }

  .p-dialog-title {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 32px;
  }

  .p-dialog-header-icons {
  }

  //&.p-dialog {
  //  .p-dialog-header-icon {
  //    overflow: visible;
  //    position: initial;
  //  }
  //}

  .p-dialog-header-close {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: transparent;

    border: 0;
    &:before {
      content: $icon-cancel;
      font-family: icomoon;
      font-size: 18px;
      color: var(--color-text-primary);

    }
  }

  .p-dialog-footer{
    @extend .mt-6;

    display: flex;
    flex-direction: column-reverse;
  }

  .p-confirm-dialog-reject {
    @extend .mt-4;

    @extend .b-btn;
    @extend .b-btn--transparent;

  }

  .p-confirm-dialog-accept {
    @extend .mt-4;
    @extend .b-btn;
  }
}

.p-dialog-mask {
  background-color: var(--color-black--50);
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

}
