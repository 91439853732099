.g-primereact-panel {
  padding: 1rem;
  background-color: var(--color-white-40);
  border-radius: 0.5rem;

  .p-panel-header-icon {
    border: 0;
    background-color: transparent;

    .pi {
      transform: rotate(0);

      &:before {
        content: $icon-next;
        font-family: 'icomoon';
        color: var(--color-white);
        position: relative;
        width: 50px;
        height: 50px;
      }
    }

    .pi-plus {
      transform: rotate(-90deg);
    }
  }

  .p-toggleable-content {
    overflow: hidden;
    padding: 4rem 2rem 2rem 2rem;
  }


  .p-toggleable-content-enter-active.p-toggleable-content-enter {
    height: 100%;
  }

  .p-toggleable-content-exit-active.p-toggleable-content-exit {
    height: 0;
    padding: 0;
  }

}
