

@font-face {
  font-family: "Raleway";
  src: url("../../assets/fonts/Raleway-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../../assets/fonts/Raleway-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Raleway";
  src: url("../../assets/fonts/Raleway-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
}


:root {
  --font-family-primary: 'Raleway';
  --font-family-logo: 'Cervanttis';
}
