.b-badge {
  min-width: 7rem;
  width: 7rem;
  min-height: 7rem;
  height: 7rem;
  background-color: var(--color-white);
  border: 2px solid var(--color-white);
  color: var(--color-text-primary);
  border-radius: 100%;
  padding: 1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &--facebook {
    background-color: var(--color-facebook);
  }

  &--success {
    background-color: var(--color-success);
  }

  &--error {
    background-color: var(--color-error);
  }

  &--active {
    background-color: var(--color-primary);
  }

  &--square {
    border-radius: 0;
    height: auto;
  }
  &--no-border-radius{
    border-radius: 0;
  }
  &--s {
    min-width: 4.5rem;
    width: 4.5rem;
    min-height: 4.5rem;
    height: 4.5rem;
  }
  &--secondary{
    border: 2px solid var(--color-primary);
    color: var(--color-white);
    background-color: transparent;
  }
  &--y{
    border-top: 2px solid var(--color-primary);
    border-left: 0;
    border-right: 0;
    border-bottom: 2px solid var(--color-primary);
  }
  &--no-br{
    border-radius: 0;
  }
  &--auto{
    height: auto;
    width:  auto;
    min-height: auto;
  }
}
