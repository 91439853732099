.c-text-transition{
  user-select: none;
  //padding: 10vh 0 25vh;
  --marquee-width: 100vw;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
  --item-font-size: 10vw;
  counter-reset: menu;
  position: relative;
  &__front-text{
    display: inline-block;
    cursor: pointer;
    position: relative;
    text-stroke: 1.5px #000;
    text-fill-color: transparent;
    color: transparent;
    transition: opacity 0.4s;
    @extend %text-style;


    //&:hover{
    //  transition-duration: 0.1s;
    //  opacity: 0;
    //  ~ .c-text-transition__content  .c-text-transition__inner {
    //    animation-play-state: running;
    //    opacity: 1;
    //    transition-duration: 0.4s;
    //  }
    //}
    transition-duration: 0.1s;
    opacity: 0;
    ~ .c-text-transition__content  .c-text-transition__inner {
      animation-play-state: running;
      opacity: 1;
      transition-duration: 0.4s;
    }
  }
  &__content{
    position: absolute;
    top: 0;
    left: 0;
    width: var(--marquee-width);
    //overflow: hidden;
    pointer-events: none;
    //mix-blend-mode: color-burn;
  }
  &__inner{
    width: fit-content;
    display: flex;
    position: relative;
    transform: translate3d(var(--move-initial), 0, 0);
    animation: marquee 5s linear infinite;
    animation-play-state: paused;
    opacity: 0;
    transition: opacity 0.1s;
  }
  &__text{
    text-align: center;
    font-style: italic;
    @extend %text-style;

  }

  %text-style{
    white-space: nowrap;
    font-size: var(--item-font-size);
    padding: 0 1vw;
    font-weight: 900;
    line-height: 1.15;
  }
  @keyframes marquee {
    0% {
      transform: translate3d(var(--move-initial), 0, 0);
    }

    100% {
      transform: translate3d(var(--move-final), 0, 0);
    }
  }
}

