.fade-enter {
  opacity: 1;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  animation: 310ms ease-in-out pageTransition;
}

.fade-exit.fade-exit-active {
  animation: 310ms ease-in-out pageTransition reverse;
}


.home {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  background-repeat: repeat;
}

@keyframes pageTransition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

