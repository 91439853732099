.b-text {
  color: var(--color-white);
  font-family: var(--font-family-primary);
  font-size: 4rem;

  &--important {
    color: var(--color-primary);
    font-weight: bold;
  }

  &--s {
    font-size: 1.5rem;
  }

  &--m {
    font-size: 2rem;
  }

  &--sec {
    color: var(--color-grey-40)
  }
}
