.b-btn {
  background-color: var(--color-primary);
  color: var(--color-text-primary);
  padding: 2rem;
  border-radius: 1rem;
  width: 100%;
  height: 66px;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  text-align: center;
  text-decoration: none;
  font-family: var(--font-family-primary);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 3px solid rgba(255, 255, 255, 0.30);
  transition: .3s background-color;

  &:hover {
    color: var(--color-text-primary);
  }

  &--auto {
    width: auto;
  }

  &--inverted {
    background-color: var(--color-text-primary);
    color: var(--color-white);

    &:hover {
      color: var(--color-white);
    }
  }
  &--transparent {
    background-color: transparent;
    color: var(--color-text-primary);

    &:hover {
      color: var(--color-text-primary);
    }
  }

  &--selected {
    background-color: var(--color-text-primary);
    color: var(--color-white);

    &:hover {
      color: var(--color-white);
    }
  }

  &__background-icon {
    position: absolute;
    top: -10px;
    right: 10px;
    font-size: 80px;
    transform: rotate(10deg);
  }

}
