.b-input {
  width: 100%;
  padding: 1rem;
  border: 0;
  border-radius: 1rem;
  height: 67px;

  &[readonly] {
    opacity: 0.5;
  }
}
