.b-checkbox {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 8rem;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  height: 9rem;
  margin: 0;

  &__box {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .b-checkbox__check {
      background-color: var(--color-white);
    }

    &:checked ~ .b-checkbox__check:after {
      width: 180%;
      opacity: 0.5;
      transform: translate(-50%, -50%) rotate(45deg);
      display: block;
    }
  }

  &__check {
    position: absolute;
    height: 6.5rem;
    width: 6.5rem;
    border-radius: .5rem;
    border: 2px solid;
    display: inline-block;
    left: 0;

    &:after {
      content: '';
      opacity: 0;
      position: absolute;
      border-radius: 2px;
      width: 100%;
      height: 2px;
      background-color: var(--color-white);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: transform 0.3s, opacity 0.3s;

    }

  }
}
