.b-icon {
  color: var(--color-white);
  font-size: 4rem;
  &--s{
    font-size: 3rem;
  }
  &--actual-user{
    color: var(--color-actual-user);
  }
}
