.c-board-game {
  --padding: 3rem;
  --local-max-width: 269px;
  --local-max-height: 373.92px;

  position: fixed;
  background-image: url("../../../../assets/images/pattern.webp");
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    max-width: 450px;
    width: 90%;
    top: 50%;

    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  &__btn {
    position: fixed;
    bottom: 2%;
    width: 90%;
    left: 5%;

  }

}

