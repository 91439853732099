@import "../imports/bootstrap/breakpoints.imports";

.b-text-start {
  font-size: 12vw;
  text-transform: uppercase;
  font-weight: 800;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  text-align: left;
  color: var(--color-white);
  //border-bottom: 1px solid;
  //border-top: 1px solid;
  line-height: 12vw;
  display: inline-block;
  margin: 18% 0;
  position: relative;
  @include media-breakpoint-up(lg) {
    font-size: 80px;
  }
  &:hover {
    &:before {
      top: 0;
      transform: rotate(0deg);
    }

    &:after {
      transform: rotate(0deg);
      top: 100%;
    }
  }

  &:before {
    @extend %line-style;
    top: 25%;
    transform: rotate(45deg);
  }

  &:after {
    @extend %line-style;
    top: 75%;
    transform: rotate(-45deg);
  }
  &--fixed{
    position: fixed;
    bottom: 5%;
    right: 5%;
    width: auto;
    margin: 0;
  }
}

%line-style {
  position: absolute;

  width: 70%;
  right:  0;
  height: 2px;
  margin-top: -1px;
  background: var(--color-primary);
  content: '';
  transition: transform 0.3s,top 0.3s, opacity 0.3s;
  pointer-events: none;
}
