.b-radio {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 8rem;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  height: 9rem;
  margin: 0;

  &__box {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .b-radio__check {
      background-color: var(--color-white);

    }

    &:checked ~ .b-radio__check:before {
      //display: block;
      opacity: 1;

    }

  }

  &__check {
    position: absolute;
    height: 6.5rem;
    width: 6.5rem;
    border-radius: 50%;
    border: 2px solid;
    display: inline-block;
    left: 0;
    transition: background-color 0.3s;

    &:before {
      font-family: 'icomoon';
      position: absolute;
      //display: none;
      opacity: 0;
      transition: opacity 0.3s;

      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: var(--color-text-primary);
    }


  }
}
