.b-mini-card {
  position: relative;
  height: 5rem;
  width: 3.5rem;
  min-width: 3.5rem;
  border-radius: .5rem;
  border: 2px solid;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:before {
    font-family: 'icomoon';
    font-size: 2rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &--l{
    border: 2px solid;
    height: 6.5rem;
    width: 4.5rem;
    min-width: 4.5rem;
  }
  &--plus{
    &:before{
      content: "+1"
    }
  }


  &--minus{
    &:before{
      content: "-1"
    }
  }

}
