.roulette {
    overflow: hidden;
}

.roulette__list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.roulette__prize {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
   }
